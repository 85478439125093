<template>
  <div class="block-configurator-container" id="configurator" :class="device_platform">
    <div class="header-text">Конфигуратор</div>
    <div class="model-chooser">
      <div class="nav-container">
        <div class="nav-item" v-for="(car, index) in cars" :key="index" :class="{ active: cur_car.index === car.id }"
          @click="
            cur_car = {
              index: car.id,
              name: car.id,
              color: cars_data[car.id].colors[0],
              desc: cars_data[car.id].desc,
            }
            ">
          {{ car.name }}
        </div>
      </div>
    </div>
    <div class="content-container">
      <div class="background"></div>
      <div class="model-container">
        <!-- <div class="model-description">
          <p class="description-item">5 лет гарантии</p>
          <p class="description-item">Оцинкованный кузов</p>
          <p class="description-item">Адаптирован под АИ-92</p>
        </div> -->
        <div class="model-wrapper">
          <div class="name-container">
            <div class="name-content">
              <div class="name-wrapper">
                <div class="name-second">
                  <span class="name-first-line">Geely </span>{{ cars_data[cur_car.name].name }}
                </div>
              </div>
              <div class="btn-blue btn__arrow_2" @click="
                getCall({
                  type: 'sale',
                  form: 'configurator',
                  button_text: 'ОБРАТНЫЙ ЗВОНОК',
                  callback_title: 'УТОЧНИТЬ НАЛИЧИЕ',
                })
                ">
                Уточнить наличие
              </div>
            </div>
            <div class="guarantee">
              <p class="guarantee__text">5 лет гарантии</p>
              <img class="guarantee__icon" src="../images/icons/guarantee.png" alt="" />
            </div>
          </div>
          <div class="car-img-wrapper" :class="[cur_car.name]">
            <div class="car-img" :class="[cur_car.color, cur_car.name]"></div>
            <div class="desc-list">
              <div class="desc-item" :class="`desc-item--${desc_item.alias}-${cur_car.index}`"
                v-for="(desc_item, k) in cur_car.desc" :key="`desc-item-${k}`">
                <div class="desc-item__dot" :class="{ 'desc-item__dot--active': desc_item.alias === active_point }"
                  @click="setActivePoint(desc_item.alias)"></div>
                <div class="desc-item__content">
                  <div class="desc-item__text-wrapper"
                    :style="{ visibility: desc_item.alias === active_point ? 'visible' : 'hidden' }">
                    <p class="desc-item__textline" v-for="(line, j) in desc_item.textlines" :key="`textline-${j}`">{{ line
                    }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="colors-wrapper">
          <div class="color" v-for="color in cars_data[cur_car.name].colors" :class="color"
            @click="cur_car.color = color"></div>
        </div>
      </div>
      <div class="futures-show-button btn-blue" v-if="device_platform === 'mobile'" @click="show.futures = !show.futures">
        Технические характеристики
      </div>
      <div class="features-container" :class="{ ['features-container--active']: show.futures }">
        <div class="feature-wrapper" v-if="cars_data[cur_car.name].dimensions">
          <div class="feature-icon dimensions"></div>
          <div class="feature-data">
            <div class="feature-header">Габариты (мм)</div>
            <div class="feature-content">
              {{ cars_data[cur_car.name].dimensions }}
            </div>
          </div>
        </div>
        <div class="feature-wrapper" v-if="cars_data[cur_car.name].transmission">
          <div class="feature-icon transmission"></div>
          <div class="feature-data">
            <div class="feature-header">Трансмиссия</div>
            <div class="feature-content">
              {{ cars_data[cur_car.name].transmission }}
            </div>
          </div>
        </div>
        <div class="feature-wrapper" v-if="cars_data[cur_car.name].engine">
          <div class="feature-icon engine"></div>
          <div class="feature-data">
            <div class="feature-header">Двигатель (л.с.)</div>
            <div class="feature-content" v-for="engine in cars_data[cur_car.name].engine">
              {{ engine }}
            </div>
          </div>
        </div>
        <div class="feature-wrapper" v-if="cars_data[cur_car.name].fuel && cars_data[cur_car.name].fuel.length
          ">
          <div class="feature-icon fuel"></div>
          <div class="feature-data">
            <div class="feature-header">Расход топлива (л.)</div>
            <div class="feature-content" v-if="cars_data[cur_car.name].fuel" v-for="fuel in cars_data[cur_car.name].fuel">
              {{ fuel }}
            </div>
          </div>
        </div>
        <div class="feature-wrapper" v-if="cars_data[cur_car.name].gear && cars_data[cur_car.name].gear.length
          ">
          <div class="feature-icon gear"></div>
          <div class="feature-data">
            <div class="feature-header">Привод колес</div>
            <div class="feature-content" v-for="gear in cars_data[cur_car.name].gear">
              {{ gear }}
            </div>
          </div>
        </div>
        <div class="feature-wrapper" v-if="cars_data[cur_car.name].warranty">
          <div class="feature-icon warranty"></div>
          <div class="feature-data">
            <div class="feature-header">Гарантия</div>
            <div class="feature-content">
              {{ cars_data[cur_car.name].warranty }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(car, i) in valid_cars" :key="`car-${car.id}`">
          <div class="name-nav-wrapper">
            <div class="name-wrapper">
              <span class="name">Geely </span>
              {{ car.name }}
            </div>
            
            <div class="navigation">
              <div class="swiper-button-prev" @click="
                cur_car = {
                  index: valid_cars[i - 1].id,
                  name: valid_cars[i - 1].id,
                  color: cars_data[valid_cars[i - 1].id].colors[0],
                  desc: cars_data[valid_cars[i - 1].id].desc,
                }
                "></div>
              <div class="swiper-button-next" @click="
                cur_car = {
                  index: valid_cars[i + 1].id,
                  name: valid_cars[i + 1].id,
                  color: cars_data[valid_cars[i + 1].id].colors[0],
                  desc: cars_data[valid_cars[i + 1].id].desc,
                }
                "></div>
            </div>
          </div>
          <div class="guarantee">
            <p class="guarantee__text">5 лет гарантии</p>
            <img class="guarantee__icon" src="../images/icons/guarantee.png" alt="" />
          </div>
          <div class="btn-blue btn__arrow_2" @click="
            getCall({
              type: 'sale',
              form: 'configurator',
              button_text: 'ОБРАТНЫЙ ЗВОНОК',
              callback_title: 'УТОЧНИТЬ НАЛИЧИЕ',
            })
            ">
            Уточнить наличие
          </div>
          <!-- <div class="navigation">
            <div class="swiper-button-prev" @click="
              cur_car = {
                index: valid_cars[i - 1].id,
                name: valid_cars[i - 1].id,
                color: cars_data[valid_cars[i - 1].id].colors[0],
                desc: cars_data[valid_cars[i - 1].id].desc,
              }
              "></div>
            <div class="swiper-button-next" @click="
              cur_car = {
                index: valid_cars[i + 1].id,
                name: valid_cars[i + 1].id,
                color: cars_data[valid_cars[i + 1].id].colors[0],
                desc: cars_data[valid_cars[i + 1].id].desc,
              }
              "></div>
          </div> -->
          <ul class="features">
            <li class="feature" v-for="desc_item in cars_data[car.id].desc" :key="`desc-item-${desc_item.alias}`">
              <p class="desc-item__textline" v-for="(line, n) in desc_item.textlines" :key="`line-${n}`">{{ line }}</p>
            </li>
          </ul>
          <div class="car-img-wrapper">
            <div class="car-img" :class="[cur_car.color, cur_car.name]"></div>
          </div>
          <div class="colors-wrapper">
            <div class="color" v-for="color in cars_data[car.id].colors" :class="color" @click="cur_car.color = color">
            </div>
          </div>
          <div class="btn-blue btn-specs" @click="show_specs = !show_specs">
            Технические характеристики
          </div>
          <div class="features-container" :class="{ ['features-container--active']: show.futures }" v-if="show_specs">
            <div class="features-container-close" @click="show_specs = !show_specs">X</div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].dimensions">
              <div class="feature-icon dimensions"></div>
              <div class="feature-data">
                <div class="feature-header">Габариты (мм)</div>
                <div class="feature-content">
                  {{ cars_data[cur_car.name].dimensions }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].transmission">
              <div class="feature-icon transmission"></div>
              <div class="feature-data">
                <div class="feature-header">Трансмиссия</div>
                <div class="feature-content">
                  {{ cars_data[cur_car.name].transmission }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].engine">
              <div class="feature-icon engine"></div>
              <div class="feature-data">
                <div class="feature-header">Двигатель (л.с.)</div>
                <div class="feature-content" v-for="engine in cars_data[cur_car.name].engine">
                  {{ engine }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].fuel && cars_data[cur_car.name].fuel.length
              ">
              <div class="feature-icon fuel"></div>
              <div class="feature-data">
                <div class="feature-header">Расход топлива (л.)</div>
                <div class="feature-content" v-if="cars_data[cur_car.name].fuel"
                  v-for="fuel in cars_data[cur_car.name].fuel">
                  {{ fuel }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].gear && cars_data[cur_car.name].gear.length
              ">
              <div class="feature-icon gear"></div>
              <div class="feature-data">
                <div class="feature-header">Привод колес</div>
                <div class="feature-content" v-for="gear in cars_data[cur_car.name].gear">
                  {{ gear }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].warranty">
              <div class="feature-icon warranty"></div>
              <div class="feature-data">
                <div class="feature-header">Гарантия</div>
                <div class="feature-content">
                  {{ cars_data[cur_car.name].warranty }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

import Mixin from "../common/mixin";
import CARS from "../common/cars-info";
import filters from "../common/finance";
import CallbackInput from "./callback-form/callback-input";
import { eventBus } from "../main";
export default {
  name: "block-configurator",
  components: { CallbackInput },
  mixins: [Mixin, filters],
  data() {
    const swiper = null;
    const active_point = "";
    const show_specs = false;
    const valid_cars = CARS.CARS.filter((car) => car.configurable);
    const cars_data = {
      "preface": {
        name: "PREFACE",
        dimensions: "4825 х 1880 х 1469",
        // transmission: "8АТ",
        engine: ["2.0T - 200 л.с."],
        engine_more: ["2.0T - 200 л.с."],
        gear: ["Передний (2WD)"],
        gear_more: ["Передний (2WD)"],
        fuel: ["от 5,5 л."],
        fuel_more: ["от 5,5 л."],
        warranty: "5 лет / 150 000 км",
        colors: [
          "white",
          "gray",
          "black",
          "green",
          "blue",
          "violet",
        ],
        desc: [
          {
            alias: "adap",
            textlines: [
              "Адаптивный круиз-контроль" //+
            ],
          },
          {
            alias: "display",
            textlines: [
              "Мультимедийный дисплей 12,3”" //+
            ]
          },
          {
            alias: "audio",
            textlines: [
              "Премиальная аудиосистема" //+
            ],
          },
          {
            alias: "seat",
            textlines: [
              "Передние сидения с вентиляцией" //+
            ],
          },
          {
            alias: "winter-options",
            textlines: [
              "Пакет «Зимних» опций" //+
            ],
          }
        ],
      },
      "cityray": {
        name: "CITYRAY",
        dimensions: "4825 х 1880 х 1469",
        engine: ["1.5T - 147 л.с."],
        engine_more: ["2.0T - 200 л.с."],
        gear: ["Передний (2WD)"],
        gear_more: ["Передний (2WD)"],
        fuel: ["6,5 л"],
        fuel_more: ["6,5 л"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "grey-blue",
          "white",
          "gray",
        ],
        desc: [
          {
            alias: "kuzov",
            textlines: [
              "Панорамная крыша с люком"
            ],
          },
          {
            alias: "display",
            textlines: [
              "Сенсорный мультимедиа экран 13,2”"
            ]
          },
          {
            alias: "seat",
            textlines: [
              "Регулировка передних сидений в 6 направлениях"
            ],
          },
          {
            alias: "backlight",
            textlines: [
              "Атмосферная подсветка интерьера"
            ],
          },
          {
            alias: "winter-options",
            textlines: [
              "Пакет «Зимних» опций"
            ],
          }
        ],
      },
      "okavango": {
        name: "OKAVANGO",
        dimensions: "4860 х 1910 х 1770",
        // transmission: "8АТ",
        engine: ["2.0T - 200 л.с."],
        engine_more: ["2.0T - 200 л.с."],
        gear: ["Передний (2WD)"],
        gear_more: ["Передний (2WD)"],
        fuel: ["от 6,5 л."],
        fuel_more: ["от 6,5 л."],
        warranty: "5 лет / 150 000 км",
        colors: [
          "white",
          "gray",
          "blue",
          "black",
        ],
        desc: [
          {
            alias: "camera",
            textlines: [
              "Система камер кругового обзора 360°" //+
            ],
          },
          {
            alias: "display",
            textlines: [
              "Мультимедийный дисплей 12,3”" //+
            ]
          },
          {
            alias: "roof",
            textlines: [
              "Панорамная крыша с люком" //+
            ],
          },
          {
            alias: "engine",
            textlines: [
              "3 ряда сидений" //+
            ],
          },
          {
            alias: "winter-options",
            textlines: [
              "Пакет «Зимних» опций" //+
            ],
          },
          {
            alias: "adap",
            textlines: [
              "Адаптивный круиз-контроль" //+
            ],
          }
        ],
      },
      "atlas_new": {
        name: "ATLAS NEW",
        dimensions: "4670 х 1900 х 1705",
        // transmission: "8АТ",
        engine: ["2.0Т - 200"],
        engine_more: ["2.0Т - 200"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        fuel: ["6.3"],
        fuel_more: ["6.3"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "silver",
          "white",
          "blue",
          "green",
          "black",
        ],
        desc: [
          // new
          {
            alias: "camera",
            textlines: [
              "Система камер кругового обзора 360°"
            ],
          },
          {
            alias: "display",
            textlines: [
              "Вертикальный мультимедийный дисплей 13,2”"
            ]
          },
          {
            alias: "roof",
            textlines: [
              "Панорамная крыша с люком"
            ],
          },
          {
            alias: "engine",
            textlines: [
              "Двигатель 2.0Т - 200",
              "Новый турбированный двигатель 2,0 л"
            ],
          },
          {
            alias: "winter-options",
            textlines: [
              "Пакет «Зимних» опций"
            ],
          },
          {
            alias: "adap",
            textlines: [
              "Адаптивный круиз-контроль"
            ],
          }
        ],
      },
      monjaro: {
        name: "MONJARO",
        dimensions: "4770 х 1895 х 1689",
        transmission: "8АТ",
        engine: ["2.0Т - 238"],
        engine_more: ["2.0Т - 238"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        fuel: ["7.3"],
        fuel_more: ["7.3"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "green-azure",
          "black",
          "blue",
          "gray",
          "silver",
          "white"
        ],
        desc: [
          // new
          {
            alias: "auto",
            textlines: [
              "8-ступенчатый классический «автомат»",
              "с гидротрансформатором",
            ],
          },
          {
            alias: "intel",
            textlines: [
              "Интеллектуальный полный привод",
              "4WD 6-го поколения",
            ],
          },
          {
            alias: "optic",
            textlines: [
              "Оптика оригинального дизайна",
              "с двойными горизонтальными ходовыми огнями",
            ],
          },
          {
            alias: "chain",
            textlines: [
              "Агрессивная вогнутая решётка",
              "радиатора с вертикальными ламелями",
            ],
          },
          {
            alias: "display",
            textlines: [
              "Большой сенсорный дисплей мультимедиа",
              "диагональю 12,3” и дисплей для переднего пассажира 12,3”",
            ]
          },
          // origin
          {
            alias: "kuzov",
            textlines: [
              "Оцинкованный кузов"
            ],
          },
          {
            alias: "engine",
            textlines: [
              "Двигатель 2.0Т - 238"
            ],
          },
          {
            alias: "gear",
            textlines: [
              "Полный привод колес"
            ],
          },
          {
            alias: "fuel",
            textlines: [
              "Расход топлива 7.3"
            ],
          },
          {
            alias: "adap",
            textlines: [
              "Адаптирован под АИ-92"
            ],
          }
        ],
      },
      atlas: {
        name: "Atlas",
        dimensions: "4519 х 1831 х 1694",
        transmission: "6MT / 6AT",
        engine: ["2.0 - 139", "2.4 - 149", "1.8 - 184"],
        engine_more: ["2.0 - 139"],
        fuel: [
          "2.0MT 2WD - от 8.1",
          "2.4AT 2WD - от 8.9",
          "2.4AT 4WD - от 9.4",
          "1.8AT - от 8.5",
        ],
        fuel_more: ["8.1"],
        gear: ["Передний", "Полный"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["red", "bez", "brown", "black", "white", "serebro", "gray"],
      },

      "atlas-pro": {
        name: "Atlas-PRO",
        dimensions: "4544 х 1831 х 1713",
        transmission: "7DCT",
        engine: ["1.5 - 177"],
        engine_more: ["1.5 - 177"],
        fuel: ["1.5T - от 7,4"],
        fuel_more: ["7.4"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        warranty: "5 лет / 150 000 км",
        colors: ["red", "black", "gray", "silver", "white"],
        desc: [
          // new
          {
            alias: "valeo",
            textlines: [
              "Мощные светодиодные фары Valeo",
            ],
          },
          {
            alias: "roof",
            textlines: [
              "Огромная панорамная крыша",
              "площадью 1,2 кв. м",
            ],
          },
          {
            alias: "disc",
            textlines: [
              "Изысканные 18-дюймовые",
              "диски из алюминиевого сплава",
            ],
          },
          {
            alias: "display",
            textlines: [
              "Сенсорный экран мультимедиа",
              "высококонтрастный 12,3-дюймовый",
            ],
          },
          {
            alias: "light",
            textlines: [
              "42-цветная система",
              "атмосферной подсветки",
            ],
          },
          // origin
          {
            alias: "kuzov",
            textlines: [
              "Оцинкованный кузов"
            ],
          },
          {
            alias: "engine",
            textlines: [
              "Двигатель 1.5 - 177"
            ],
          },
          {
            alias: "gear",
            textlines: [
              "Полный привод колес"
            ],
          },
          {
            alias: "fuel",
            textlines: [
              "Расход топлива 7.4"
            ],
          },
          {
            alias: "adap",
            textlines: [
              "Адаптирован под АИ-92"
            ],
          }
        ],
      },

      "emgrand-x7": {
        name: "Emgrand x7",
        dimensions: "4500 x 1834 x 1707",
        transmission: "6MT / 6AT",
        engine: ["1.8 – 131", "2.0 – 139"],
        fuel: false,
        gear: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["bez", "brown", "black", "white", "serebro", "gray"],
      },
      "emgrand-7": {
        name: "Emgrand 7",
        dimensions: "4631 x 1789 x 1479",
        transmission: "5МТ / CVT",
        engine: ["1.5 – 103", "1.8 - 133"],
        fuel: ["1.5МТ - от 7.5", "1.8MT - от 7.9", "1.8CVT - от 7.8"],
        gear: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["black", "white", "serebro", "gray"],
      },
      'belgee-x50': {
        name: "Belgee X50",
        dimensions: "4330 x 1800 x 1609",
        engine: ["1.5T – 150"],
        engine_more: ["1.5T – 150"],
        fuel: ["1.5T - от 6.4"],
        fuel_more: ["6.4"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "white",
          "red",
         /*  "blue", */
          "silver",
          "gray",
          "black",
/*           "orange" */
        ],
        desc: [
          // new
          {
            alias: "engine",
            textlines: [
              "Двигатель 1.5T – 150",
              "Новый турбированный двигатель 1,5 л.",
            ],
          },
          {
            alias: "selector",
            textlines: [
              "Электронный селектор КПП",
            ],
          },
          {
            alias: "assist",
            textlines: [
              "Функция ассистента",
              "автоматической парковки",
            ],
          },
          {
            alias: "panel",
            textlines: [
              "7-дюймовая цифровая",
              "приборная панель",
            ],
          },
          {
            alias: "display",
            textlines: [
              "Мультимедийный сенсорный экран",
              "высокого разрешения 10,25”"
            ],
          },
          // origin
          {
            alias: "kuzov",
            textlines: [
              "Оцинкованный кузов"
            ],
          },
          {
            alias: "gear",
            textlines: [
              "Передний привод колес"
            ],
          },
          {
            alias: "fuel",
            textlines: [
              "Расход топлива 6.4"
            ],
          },
          {
            alias: "adap",
            textlines: [
              "Адаптирован под АИ-92"
            ],
          }
        ],
      },
      //

      "coolray_new": {
        name: "Coolray NEW",
        dimensions: "4380 x 1810 x 1615",
        engine: ["1.5T – 147"],
        engine_more: ["1.5T – 147"],
        fuel: ["1.5T - от 5.5"],
        fuel_more: ["6.4"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "gray",
          "white",
          "light-blue",
          "blue",
          "red"
        ],
        desc: [
          // new
          {
            alias: "engine",
            textlines: [
              "Двигатель 1.5T – 147",
              "Новый турбированный двигатель 1,5 л.",
            ],
          },
          // {
          //   alias: "selector",
          //   textlines: [
          //     "Электронный селектор КПП",
          //   ],
          // },
          {
            alias: "assist",
            textlines: [
              "Ассистент автоматической парковки",
            ],
          },
          // {
          //   alias: "panel",
          //   textlines: [
          //     "7-дюймовая цифровая",
          //     "приборная панель",
          //   ],
          // },
          {
            alias: "display",
            textlines: [
              "12,3 мультимедийный сенсорный экран"
            ],
          },
          // origin
          {
            alias: "kuzov",
            textlines: [
              "Панорамная крыша с люком"
            ],
          },
          {
            alias: "gear",
            textlines: [
              "Разгон 0-100 км/ч – 8,1 с"
            ],
          },
          {
            alias: "winter-options",
            textlines: [
              "Пакет зимних опций"
            ],
          },
          // {
          //   alias: "adap",
          //   textlines: [
          //     "Адаптирован под АИ-92"
          //   ],
          // }
        ],
      },

      "emgrand": {
        name: "Emgrand",
        dimensions: "4638 x 1822 x 1460",
        engine: ["1.5T - 122"],
        engine_more: ["1.5T - 122"],
        transmission: "1.5 МТ / 1.5 АТ",
        fuel: ["5.8 / 6.4"],
        fuel_more: ["6.4"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "black",
          "glaze",
          "blue",
          "gold",
          "gray",
          "white"
        ],
        desc: [
          {
            alias: "engine",
            textlines: [
              "Атмосферный двигатель 1.5 л."
            ],
          },
          {
            alias: "winter-options",
            textlines: [
              "Зимние опции"
            ],
          },
          {
            alias: "kuzov",
            textlines: [
              "Люк с электроприводом и шторкой"
            ],
          },
          {
            alias: "light",
            textlines: [
              "Двухцветная отделка салона"
            ],
          },
          {
            alias: "display",
            textlines: [
              "12,3 мультимедийный сенсорный экран высокого разрешения"
            ],
          },
        ],
      },

      tugella: {
        name: "TUGELLA",
        dimensions: "4605 х 1878 х 1643",
        transmission: "8АТ",
        engine: ["2.0Т - 238"],
        engine_more: ["2.0Т - 238"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        fuel: ["8.1"],
        fuel_more: ["8.1"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "blue",
          "black",
          "black-metallic",
          "bloody",
          "silver",
          "white",
        ],
      },

      "tugella-facelift": {
        name: "TUGELLA",
        dimensions: "4605 х 1878 х 1643",
        transmission: "8АТ",
        engine: ["2.0Т - 238"],
        engine_more: ["2.0Т - 238"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        fuel: ["8.1"],
        fuel_more: ["8.1"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "gray",
          "black",
          "gray-blue",
          "white"
        ],
        desc: [
          // new
          {
            alias: "roof",
            textlines: [
              "Панорамная крыша с люком",
            ],
          },
          {
            alias: "system",
            textlines: [
              "Интеллектуальная система",
              "управления дальним светом",
            ],
          },
          {
            alias: "display",
            textlines: [
              "Большой сенсорный дисплей",
              "мультимедиа диагональю 12,3”"
            ],
          },
          {
            alias: "audio",
            textlines: [
              "Премиальная аудиосистема",
              "с 12 динамиками",
            ],
          },
          {
            alias: "light",
            textlines: [
              "Многоцветная система",
              "атмосферной подсветки салона",
            ],
          },
          // origin
          {
            alias: "kuzov",
            textlines: [
              "Оцинкованный кузов"
            ],
          },
          {
            alias: "engine",
            textlines: [
              "Двигатель 2.0Т - 238"
            ],
          },
          {
            alias: "gear",
            textlines: [
              "Полный привод колес"
            ],
          },
          {
            alias: "fuel",
            textlines: [
              "Расход топлива 8.1"
            ],
          },
          {
            alias: "adap",
            textlines: [
              "Адаптирован под АИ-92"
            ],
          }
        ],
      },
    };
    return {
      swiper,
      show_specs,
      active_point,
      cars: valid_cars,
      cur_car: {
        index: valid_cars[0].id,
        name: valid_cars[0].id,
        color: cars_data[valid_cars[0].id].colors[0],
        desc: cars_data[valid_cars[0].id].desc,
      },
      cars_data: cars_data,
      show: {
        futures: false,
      },
      valid_cars,
    };
  },
  mounted() {
    console.log(this.device_platform);
    this.show.futures = this.device_platform === "mobile" ? false : true;
    eventBus.$on("setActiveCar", (id) => {
      this.setActiveCar(id);
    });

    if (!this.swiper) {
      this.swiper = new Swiper(".swiper-container", {
        allowTouchMove: false,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
      });
    }
  },
  beforeDestroy() {
    eventBus.$off("setActive");
  },
  computed: {
    imgs_array() {
      const ret = [];
      for (let i = 1; i <= this.cars[this.cur_car.index]["imgs"]; i++) {
        ret.push(i);
      }

      return ret;
    },
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    setActivePoint(alias) {
      this.active_point = alias;
    },
    setActiveCar(id) {
      this.cur_car = {
        index: id,
        name: id,
        color: this.cars_data[id].colors[0],
      };
      this.active_point = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.btn-blue {
  justify-content: flex-start;
  padding: 10px 30px;
  transition: background 0.15s linear;

  &:hover {
    width: 100%;
    justify-content: flex-start;
  }
}

.btn__arrow {
  &_2 {
    position: relative;
    background: $dark-blue;
    width: 100%;
    border: 1px solid #FFF;
    max-width: 297px;

    &:after {
      content: "";
      position: absolute;
      width: 93px;
      height: 100%;
      top: 0;
      right: 0;
      background: url(../images/icons/arrow-btn.png) no-repeat center;
      background-size: 100%;

      // @media (max-width: 400px) {
      //   right: -45px;
      // }
    }

    &:hover {
      background: $blue;
      color: #000;
      border: none;
    }
  }
}

/* .mobile {
  &.block-configurator-container {}

  .model-chooser {
    font-size: 18px;
  }

  .nav-container {
    // max-width: 320px;
    padding: 0 10px;
    justify-content: space-around;
    gap: 10px;
  }

  .nav-item {
    text-align: center;
    font-size: 12px;
  }

  .content-container {
    flex-direction: column;
    height: unset;
  }

  .background {
    height: calc(100vw * 386 / 736 + 280px);
    max-height: 500px;
  }

  .model-container {
    width: 100%;
    height: calc(100vw * 386 / 736 + 280px);
    max-height: 500px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .model-description {
    left: -10px;
    top: 27%;

    @media (max-width: 400px) {
      top: 40%;
    }
  }

  .description-item {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .model-wrapper {
    height: 100%;
  }

  .name-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .name-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .name-first-line {
    font-size: 26px;
  }

  .name-second {
    font-size: 30px;
  }

  .car-img {
    align-self: center;
    height: calc(100vw * 386 / 736);
    min-height: 200px;
    min-width: 300px;

    @media (min-width: 640px) and (max-width: 800px) {
      margin-left: 10vw;
    }
  }

  .colors-wrapper {
    padding-right: 10px;
    width: 30px;
    align-self: center;
    border: none;
    margin: 0;
  }

  .features-container {
    padding: 20px;
    width: 100%;
    max-width: 300px;
    margin: auto;
  }

  .feature-wrapper {
    height: 50%;
  }

  .color {
    width: 15px;
    height: 37px;

    &:after {
      left: -3px;
      width: 15px;
      height: 37px;
      padding: 5px 3px;
      border-bottom: 1px solid #8e908f;
    }

    &:first-of-type {
      &:after {
        top: -11px;
        height: 47px;
        border-top: 1px solid #8e908f;
      }
    }
  }
} */

/* .tablet {
  &.block-configurator-container {}

  .nav-container {
    font-size: 22px;
  }

  .content-container {
    flex-direction: column;
    height: unset;
  }

  .background {
    height: 550px;
  }

  .model-container {
    width: 100%;
    padding-left: 20px;
  }

  .model-description {
    left: -10px;
    top: 430px;
  }

  .description-item {
    font-size: 20px;
  }

  .car-img {
    align-self: center;
  }

  .colors-wrapper {
    margin-right: 20px;
    padding-right: 10px;
    width: 50px;
  }

  .features-container {
    height: 320px;
    flex-wrap: wrap;
    padding: 0 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  .feature-wrapper {
    height: 50%;
  }
} */

.desktop {
  @media (max-width: 1280px) {
    .colors-wrapper {
      margin-left: 30px;
      margin-right: 30px;
      padding-right: 20px;
    }
  }
}

.block-configurator-container {
  width: 100%;
  max-width: 1920px;
  margin-top: 0px;
  color: #fff;
}

.swiper-container {
  display: none;
}

.header-text {
  color: #000;
}

.model-chooser {
  width: 100%;
  background: $dark-blue;
  padding: 20px 0;
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
}

.nav-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 30px;
  margin: auto;
}

.nav-item {
  font-size: 25px;
  position: relative;
  cursor: pointer;

  &.active {
    font-weight: 700;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }
}

.content-container {
  width: 100%;
  max-width: 1920px;
  height: 710px;
  position: relative;
  display: flex;
}

.webp {
  .background {
    background: url("../images/configurator/conf-fon.webp") no-repeat center;
    background-size: cover;
  }
}

.background {
  width: 100%;
  height: 100%;
  background: url("../images/configurator/conf-fon.jpg") no-repeat center;
  background-size: cover;
  position: absolute;
}

.model-container,
.features-container {
  position: relative;
  padding-top: 70px;
}

.model-container {
  display: flex;
  flex-direction: row;
  width: 78%;
  justify-content: space-between;
  padding-left: 100px;
  padding-bottom: 40px;
}

.model-description {
  position: relative;
  width: 0;
  z-index: 2;
  left: -50px;
  top: 120px;
}

@media (min-width: 1700px) {
  .model-description {
    left: -70px;
    top: 300px;
  }
}

.description-item {
  white-space: nowrap;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}

.model-wrapper {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name-container {
  // display: flex;
  // align-items: center;
}

.name-content {
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.guarantee {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 15px;

  &__text {
    font-size: 24px;
    font-weight: 500;
    margin-right: 10px;
  }
}

.name-wrapper {
  font-weight: 500;
  margin-right: 80px;

  @media (max-width: 600px) {
    margin-right: 10px;
  }
}



.name-second {
  font-size: 52px;
  font-weight: 700;
  white-space: nowrap;
  margin-left: 20px;
}

.name-first-line {
  font-size: 40px;
  font-weight: 400;
}

// .webp {
//   .car-img {
//     &.atlas {
//       @each $color in "red", "bez", "brown", "black", "white", "gray", "serebro"
//       {
//         &.#{$color} {
//           background: url("../images/configurator/atlas/atlas-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }
//     &.atlas-pro {
//       @each $color in "blue", "red", "black", "gray", "serebro", "white"
//       {
//         &.#{$color} {
//           background: url("../images/configurator/atlas-pro/atlas-pro-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.emgrand-7 {
//       @each $color in "black", "white", "gray", "serebro" {
//         &.#{$color} {
//           background: url("../images/configurator/emgrand-7/emgrand-7-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.emgrand-x7 {
//       @each $color in "bez", "brown", "black", "white", "gray", "serebro" {
//         &.#{$color} {
//           background: url("../images/configurator/emgrand-x7/emgrand-x7-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.coolray {
//       @each $color in "bloody", "white" {
//         &.#{$color} {
//           background: url("../images/configurator/coolray/coolray-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.tugella {
//       @each $color in "blue" {
//         &.#{$color} {
//           background: url("../images/configurator/tugella/tugella-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }
//   }
// }

.car-img-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 900px;
  left: 50%;
  transform: translateX(-50%);

  &.coolray_new, &.atlas-pro {
    top: -13%;
  }

  &.tugella-facelift, &.belgee-x50, &.okavango {
    top: -8%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    max-width: 88%;
  }
}

.car-img {
  width: 100%;
  height: 600px;
  align-self: flex-end;
  position: relative;
  top: 20px;
  z-index: 2;

  @media (max-width: 600px) {
    order: 2;
  }

  &.preface {
    @each $color in "white",
      "gray",
      "black",
      "green",
      "blue",
      "violet" {
      &.#{$color} {
        background: url("../images/configurator/preface/preface-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.cityray {
    @each $color in "grey-blue",
      "white",
      "gray" {
      &.#{$color} {
        background: url("../images/configurator/cityray/cityray-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.okavango {
    @each $color in "white",
      "gray",
      "blue",
      "black" {
      &.#{$color} {
        background: url("../images/configurator/okavango/okavango-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.atlas_new {
    @each $color in "silver",
    "white",
    "blue",
    "green",
    "black" {
      &.#{$color} {
        background: url("../images/configurator/atlas_new/atlas-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.atlas {

    @each $color in "red",
    "bez",
    "brown",
    "black",
    "white",
    "gray",
    "serebro" {
      &.#{$color} {
        background: url("../images/configurator/atlas/atlas-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.atlas-pro {

    @each $color in "blue",
    "red",
    "black",
    "gray",
    "silver",
    "white" {
      &.#{$color} {
        background: url("../images/configurator/atlas-pro/atlas-pro-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.emgrand-7 {

    @each $color in "black",
    "white",
    "gray",
    "serebro" {
      &.#{$color} {
        background: url("../images/configurator/emgrand-7/emgrand-7-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.emgrand-x7 {

    @each $color in "bez",
    "brown",
    "black",
    "white",
    "gray",
    "serebro" {
      &.#{$color} {
        background: url("../images/configurator/emgrand-x7/emgrand-x7-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.belgee-x50 {
    @each $color in 
      "white",
      "red",
      "blue",
      "silver",
      "gray",
      "black",
      "orange"
      {
      &.#{$color} {
        background: url("../images/configurator/belgee-x50/belgee-x50-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.coolray_new {
    @each $color in 
        "gray",
        "white",
        "light-blue",
        "blue",
        "red"
      {
      &.#{$color} {
        background: url("../images/configurator/coolray_new/coolray-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.emgrand {
    top: -20px;

    @each $color in 
        "black",
        "glaze",
        "blue",
        "gold",
        "gray",
        "white"
      {
      &.#{$color} {
        background: url("../images/configurator/emgrand/emgrand-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.tugella {
    @each $color in "blue",
    "black",
    "black-metallic",
    "bloody",
    "silver",
    "white"

      {
      &.#{$color} {
        background: url("../images/configurator/tugella/tugella-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }

  &.tugella-facelift {
    @each $color in "gray", "black", "gray-blue", "white" {
      &.#{$color} {
        background: url("../images/configurator/tugella-facelift/tugella-facelift-#{$color}.png") no-repeat center;
        background-size: contain;
        // transform: scale(-1, 1);
      }
    }

    @each $color in "black", "gray-blue", "white" {
      &.#{$color} {
        transform: scale(1.13);
      }
    }
  }

  &.monjaro {
    top: 0;
    max-width: 800px;
    height: 400px;

    @each $color in "green-azure", "black", "blue", "gray", "silver", "white" {
      &.#{$color} {
        background: url("../images/configurator/monjaro/monjaro-#{$color}.png") no-repeat center;
        background-size: contain;
      }
    }
  }
}

.desc-list {
  @media (max-width: 992px) {
    padding: 20px 0;
  }
}

.desc-item {
  position: absolute;
  z-index: 3;
  display: flex;

  &__dot {
    position: relative;
    z-index: 3;
    width: 19px;
    height: 19px;
    background: #fff;
    box-shadow: 0px 0px 0px 10px #FFFFFF8A;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.75;

    &--active {
      opacity: 1;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    visibility: hidden;
  }

  &__textline {
    width: max-content;

    &:nth-child(2) {
      font-size: 12px;
    }
  }

  &--auto-monjaro {
    top: 5%;
    right: -4%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 30px;
    }
  }

  &--intel-monjaro {
    top: 56%;
    left: -15%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;
    }
  }

  &--optic-monjaro {
    top: 32%;
    left: -15%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 180px;
    }
  }

  &--chain-monjaro {
    top: 36%;
    right: -15%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 120px;
    }
  }

  &--display-monjaro {
    top: -10%;
    left: -12%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--kuzov-monjaro {
    top: -18%;
    right: 20%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--engine-monjaro {
    top: 25%;
    right: -5%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 120px;
    }
  }

  &--gear-monjaro {
    bottom: 20%;
    right: -5%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--fuel-monjaro {
    bottom: 5%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: 55px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: right;
      }
    }
  }

  &--adap-monjaro {
    top: 26%;
    left: -5%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;
    }
  }

  &--roof-tugella-facelift {
    top: 5%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--system-tugella-facelift {
    top: 40%;
    right: -10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 40px;
    }
  }

  &--display-tugella-facelift {
    top: 10%;
    left: 0%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--audio-tugella-facelift {
    top: 20%;
    right: 6%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 30px;
    }
  }

  &--light-tugella-facelift {
    bottom: 35%;
    left: -2%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--kuzov-tugella-facelift {
    top: 40%;
    left: -5%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--adap-tugella-facelift {
    top: 32%;
    left: -5%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--engine-tugella-facelift {
    top: 35%;
    right: -2%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 180px;
    }
  }

  &--gear-tugella-facelift {
    bottom: 25%;
    right: -5%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--fuel-tugella-facelift {
    bottom: 15%;
    right: 20%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: 55px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: right;
      }
    }
  }

  &--engine-belgee-x50 {
    top: 35%;
    right: -10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 80px;
    }
  }

  &--selector-belgee-x50{
    top: 57%;
    right: -17%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 40px;
    }
  }

  &--assist-belgee-x50 {
    top: 50%;
    left: -10%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--panel-belgee-x50 {
    top: 20%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--display-belgee-x50 {
    top: 12%;
    left: 5%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--kuzov-belgee-x50 {
    top: 7%;
    right: 20%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--fuel-belgee-x50 {
    bottom: 15%;
    right: -80px;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: 55px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: right;
      }
    }
  }

  &--gear-belgee-x50 {
    bottom: 22%;
    left: 10%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--adap-belgee-x50 {
    top: 35%;
    left: -10%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }


  &--engine-coolray_new {
    top: 35%;
    right: -10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 80px;
    }
  }

  &--selector-coolray_new {
    top: 57%;
    right: -17%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 40px;
    }
  }

  &--assist-coolray_new {
    top: 33%;
    left: -21%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 30px;
    }
  }

  &--panel-coolray_new {
    top: 23%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--display-coolray_new {
    top: 18%;
    left: 5%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--kuzov-coolray_new {
    top: 9%;
    right: 14%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--winter-options-coolray_new {
    bottom: 9%;
    right: -11%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: 55px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: right;
      }
    }
  }

  &--gear-coolray_new {
    bottom: 20%;
    left: 11%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--adap-coolray_new {
    top: 35%;
    left: -13%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--valeo-atlas-pro {
    top: 45%;
    right: -20%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 40px;
    }
  }

  &--roof-atlas-pro {
    top: 5%;
    right: 15%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--disc-atlas-pro {
    top: 75%;
    left: -8%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 160px;
    }
  }

  &--display-atlas-pro {
    top: 15%;
    left: 0%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--light-atlas-pro {
    top: 52%;
    left: -5%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;
    }
  }

  &--kuzov-atlas-pro {
    top: 23%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--engine-atlas-pro {
    top: 35%;
    right: 0%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 100px;
    }
  }

  &--gear-atlas-pro {
    top: 75%;
    right: -10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--fuel-atlas-pro {
    bottom: 10%;
    right: 15%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: 55px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: right;
      }
    }
  }

  &--adap-atlas-pro {
    top: 35%;
    left: -10%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--camera-atlas_new {
    top: 25%;
    left: 63%;

    .desc-item__dot {
      right: 205px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 230px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(160deg);
        transform-origin: left;
      }
    }
  }

  &--display-atlas_new {
    top: 16%;
    left: -15%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--roof-atlas_new {
    top: 14%;
    right: 12%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--engine-atlas_new {
    top: 37%;
    left: -13%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 110px;
    }
  }

  &--adap-preface {
    top: 34%;
    left: -16%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 230px;
    }
  }

  &--audio-preface {
    top: 27%;
    left: -4%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 230px;
    }
  }

  &--winter-options-atlas_new {
    bottom: 17%;
    left: -15%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      left: 80px;
      bottom: 45px;
    }

    .desc-item__text-wrapper {
      padding-right: 30px;

      &::before {
        content: "";
        position: absolute;
        left: 109px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(150deg);
        transform-origin: right;
      }
    }
  }

  &--kuzov-cityray {
    top: 12%;
    right: -5%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 120px;
    }
  }

  &--display-cityray {
    top: 12%;
    left: 3%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--seat-cityray {
    top: 8%;
    right: -18%;
    align-items: flex-end;

    .desc-item__dot {
      left: -70px;
      bottom: -120px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -111px;
        bottom: 0;
        width: 130px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-56deg);
        transform-origin: right;
      }
    }
  }

  &--backlight-cityray {
    top: 25%;
    right: 65%;
    align-items: flex-end;

    .desc-item__dot {
      left: 100%;
      bottom: -10px;
    }

    .desc-item__text-wrapper {
      padding-right: 120px;
    }
  }

  &--winter-options-cityray {
    bottom: 30%;
    right: -23%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--adap-atlas_new {
    bottom: 26%;
    right: -10%;
    align-items: flex-end;

    .desc-item__dot {
      left: -67px;
      bottom: 92px;
    }

    .desc-item__text-wrapper {
      padding-left: 30px;

      &::before {
        content: "";
        position: absolute;
        left: -115px;
        bottom: 0;
        width: 134px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(52deg);
        transform-origin: right;
      }
    }
  }

  &--roof-tugella-facelift {
    top: 5%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--engine-emgrand {
    top: 35%;
    right: 0%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 100px;
    }
  }

  &--winter-options-emgrand {
    bottom: 19%;
    right: -7%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: 55px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: right;
      }
    }
  }

  &--kuzov-emgrand {
    top: 13%;
    right: 10%;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      align-items: flex-end;
      padding-left: 60px;
    }
  }

  &--light-emgrand {
    bottom: 75%;
    left: -2%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 60px;
    }
  }

  &--display-emgrand {
    top: 12%;
    left: -15%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 0px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--camera-okavango {
    top: 19%;
    left: 85%;

    .desc-item__dot {
      right: 155px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 180px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(155deg);
        transform-origin: left;
      }
    }
  }

  &--display-okavango {
    top: 17%;
    left: 0%;
    align-items: flex-end;
    flex-direction: row-reverse;

    .desc-item__dot {
      right: -86px;
      bottom: -90px;
    }

    .desc-item__text-wrapper {
      padding-right: 40px;

      &::before {
        content: "";
        position: absolute;
        right: -109px;
        bottom: 0;
        width: 128px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(40deg);
        transform-origin: left;
      }
    }
  }

  &--roof-okavango {
    top: 9%;
    right: 12%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--seat-preface {
    top: 19%;
    right: -2%;
    align-items: flex-end;

    .desc-item__dot {
      left: -65px;
      bottom: -70px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-40deg);
        transform-origin: right;
      }
    }
  }

  &--display-preface {
    top: 15%;
    right: 18%;
    align-items: flex-end;

    .desc-item__dot {
      left: 10px;
      bottom: -107px;
    }

    .desc-item__text-wrapper {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -81px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(-90deg);
        transform-origin: right;
      }
    }
  }

  &--engine-okavango {
    top: 41%;
    left: -13%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      bottom: -9.5px;
    }

    .desc-item__text-wrapper {
      padding-right: 180px;
    }
  }

  &--winter-options-okavango {
    bottom: 9%;
    left: -19%;
    flex-direction: row-reverse;
    align-items: flex-end;

    .desc-item__dot {
      left: 80px;
      bottom: 45px;
    }

    .desc-item__text-wrapper {
      padding-right: 30px;

      &::before {
        content: "";
        position: absolute;
        left: 109px;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(150deg);
        transform-origin: right;
      }
    }
  }

  &--winter-options-preface {
    bottom: 18%;
    right: -26%;
    align-items: flex-end;

    .desc-item__dot {
      left: -95px;
      bottom: 82px;
    }

    .desc-item__text-wrapper {
      padding-left: 30px;

      &::before {
        content: "";
        position: absolute;
        left: -115px;
        bottom: 0;
        width: 134px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(41deg);
        transform-origin: right;
      }
    }
  }

  &--adap-okavango {
    bottom: 15%;
    right: -20%;
    align-items: flex-end;

    .desc-item__dot {
      left: -325px;
    bottom: 282px;
    }

    .desc-item__text-wrapper {
      padding-left: 30px;

      &::before {
        content: "";
        position: absolute;
        left: -425px;
        bottom: 0;
        width: 444px;
        height: 1px;
        background-color: #fff;
        transform: rotateZ(41deg);
        transform-origin: right;
      }
    }
  }

  @media (max-width: 992px) {
    position: static;
    padding: 0 !important;
    margin: 0 !important;
    width: 210px !important;
    border-bottom: 1px solid #000;
    transform: none !important;
  }

  @media (max-width: 600px) {
    padding-left: 22px !important;
  }

  &:not(:last-child) {
    @media (max-width: 992px) {
      margin-bottom: 5px !important;
    }
  }

  &__text {
    font-size: 15px;
    color: #fff;
  }

  &__line {
    position: relative;

    &_kuzov {
      z-index: 0;
    }

    &_engine {
      z-index: 0;
    }

    &_adap,
    &_fuel,
    &_gear {
      z-index: 10;
    }

    @media (max-width: 1400px) {
      width: 200px;
    }

    &_gear {
      @media (max-width: 1400px) {
        width: 240px;
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  &_kuzov {
    &-tugella {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }

    &-tugella-facelift {
      top: 30px;
      right: 0;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }

    &-belgee-x50 {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 130px;
      }
    }

    &-atlas-pro {
      top: 45px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 50px;
        right: 130px;
      }
    }

    &-atlas {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 90px;
      }
    }

    &-monjaro {
      top: 6px;
      right: -15px;

      @media (max-width: 1400px) {
        top: 2px;
        right: 88px;
      }
    }

    @media (max-width: 1400px) {
      width: 200px;
    }
  }

  &_engine {
    &-belgee-x50 {
      top: 120px;
      right: -150px;

      @media (max-width: 1400px) {
        right: -55px;
      }
    }

    &-atlas {
      top: 120px;
      right: -150px;

      @media (max-width: 1400px) {
        right: -55px;
      }
    }

    &-tugella {
      top: 120px;
      right: -150px;

      @media (max-width: 1400px) {
        right: -55px;
      }
    }

    &-tugella-facelift {
      top: 120px;
      right: -150px;

      @media (max-width: 1400px) {
        right: -55px;
      }
    }

    &-atlas-pro {
      top: 120px;
      right: -110px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -60px;
      }
    }

    &-monjaro {
      top: 104px;
      right: -180px;

      @media (max-width: 1400px) {
        top: 108px;
        right: -99px;
      }
    }

    @media (max-width: 1400px) {
      width: 200px;
    }
  }

  &_adapt {
    .desc-item__text {
      text-align: left;

      @media (max-width: 1400px) {
        top: -7px;
        padding-left: 40px;
      }
    }

    &-tugella {
      top: 32%;
      transform: translateY(-50%);
      left: -130px;

      @media (max-width: 1400px) {
        left: -115px;
      }
    }

    &-tugella-facelift {
      top: 32%;
      transform: translateY(-50%);
      left: -115px;

      @media (max-width: 1400px) {
        left: -90px;
      }
    }

    &-belgee-x50 {
      top: 32%;
      transform: translateY(-50%);
      left: -110px;

      @media (max-width: 1400px) {
        left: -40px;
      }
    }

    &-atlas-pro {
      top: 32%;
      transform: translateY(-50%);
      left: -110px;

      @media (max-width: 1400px) {
        left: -115px;
      }
    }

    &-atlas {
      top: 32%;
      transform: translateY(-50%);
      left: -130px;

      @media (max-width: 1400px) {
        left: -100px;
      }
    }

    &-monjaro {
      top: 32%;
      transform: translateY(-50%);
      left: -130px;

      @media (max-width: 1400px) {
        left: -100px;
      }
    }

    @media (max-width: 1400px) {
      width: 200px;
    }
  }

  &_fuel {
    .desc-item__text {
      top: 50px;
      padding-left: 70px;

      @media (max-width: 1400px) {
        top: 24px;
        padding-left: 28px;
      }
    }

    &-belgee-x50 {
      bottom: 0px;
      right: -135px;

      @media (max-width: 1400px) {
        bottom: 45px;
        right: -30px;
      }
    }

    &-atlas {
      bottom: 0px;
      right: -135px;

      @media (max-width: 1400px) {
        bottom: 45px;
        right: -30px;
      }
    }

    &-tugella {
      bottom: 0px;
      right: -135px;

      @media (max-width: 1400px) {
        bottom: 25px;
        right: -30px;
      }
    }

    &-tugella-facelift {
      bottom: 0px;
      right: -135px;

      @media (max-width: 1400px) {
        bottom: 20px;
        right: -30px;
      }
    }

    &-atlas-pro {
      bottom: 0px;
      right: -145px;

      @media (max-width: 1400px) {
        bottom: 30px;
        right: -40px;
      }
    }

    &-monjaro {
      bottom: -7px;
      right: -169px;

      @media (max-width: 1400px) {
        bottom: 30px;
        right: -124px;
      }
    }

    @media (max-width: 1400px) {
      width: 220px;
    }
  }

  &_gear {
    .desc-item__text {
      top: 40px;
      padding-right: 70px;
      padding-left: 0;

      @media (max-width: 1400px) {
        top: 22px;
        padding-right: 58px;
      }
    }

    /*     &-tugella {
      bottom: -20px;
      left: -50px;

      @media (max-width: 1400px) {
        bottom: 40px;
        left: 50px;
      }
    } */
    &-tugella {
      bottom: -20px;
      transform: translateY(-50%);
      left: -120px;

      @media (max-width: 1400px) {
        left: 0px;
      }
    }

    &-tugella-facelift {
      bottom: -20px;
      left: -50px;

      @media (max-width: 1400px) {
        bottom: 20px;
        left: 90px;
      }
    }

    &-belgee-x50 {
      bottom: 30px;
      left: -100px;

      @media (max-width: 1400px) {
        left: 0px;
      }
    }

    &-atlas-pro {
      bottom: -20px;
      left: -50px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: 90px;
      }
    }

    &-atlas {
      bottom: 0px;
      left: -100px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: 0px;
      }
    }

    &-monjaro {
      bottom: -41px;
      left: -156px;

      @media (max-width: 1400px) {
        bottom: 5px;
        left: -45px;
      }
    }

    @media (max-width: 1400px) {
      width: 240px;
    }
  }
}

.btn-blue {
  @media (max-width: 600px) {
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.colors-wrapper {
  width: 100px;
  height: 100%;
  margin-left: 50px;
  margin-right: 50px;
  padding-right: 40px;
  border-right: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.color {
  position: relative;
  width: 21px;
  height: 50px;
  transform: skewY(-50deg);
  margin: 10px auto;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: -5px;
    width: 21px;
    height: 50px;
    padding: 5px 5px;
    border-bottom: 1px solid #fff;
  }

  &:first-of-type {
    &:after {
      top: -11px;
      height: 60px;
      border-top: 1px solid #fff;
    }
  }

  &.red {
    background: #8d363e;
  }

  &.bloody {
    /*don`t know if there is a real differnce*/
    background: #8c363d;
    /*dat color from official site*/
  }

  &.bez {
    background: #c2bcae;
  }

  &.brown {
    background: #715641;
  }

  &.black {
    background: #000;
  }

  &.blue {
    background: rgb(0, 25, 106);
  }

  &.white {
    background: #fff;
  }

  &.serebro {
    background: #acacac;
  }

  &.gray {
    background: #808080;
  }

  &.silver {
    background: #c0c0c0;
  }

  &.light-blue {
    background: #88c4f2;
  }

  &.grey-blue {
    background: #517691;
  }

  &.yellow {
    background: #fdd86d;
  }

  &.orange {
    background: #ff8c00;
  }

  &.black-metallic {
    background: #000;
  }

  &.green {
    background: #3A4538;
  }

  &.green-azure {
    background: #39818D;
  }

  &.gray-blue {
    background: #6699cc;
  }

  &.gold {
    background: #9A8D7B;
  }

  &.glaze {
    background: #1C95A8;
  }

  &.violet {
    background: #9880E3;
  }
}

.features-container {
  display: none;
  flex-direction: column;

  &--active {
    display: flex;
  }
}

.feature-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.feature-icon {
  width: 25px;
  height: 32px;
  margin-right: 15px;

  @each $icon in dimensions,
  transmission,
  engine,
  fuel,
  gear,
  warranty {
    &.#{$icon} {
      background: url("../images/configurator/specifications/#{$icon}.png") no-repeat center;
      background-size: contain;
    }
  }
}

.feature-data {
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
}

.feature-header {
  margin: 5px 0 15px;
}

.feature-content {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.futures-show-button {
  width: 300px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    width: 300px;
  }

  @media (max-width: 600px) {
    margin-top: 80px;
  }
}

@media (max-width: 1680px) {
  .content-container {
    flex-direction: column;
    justify-content: center;
    height: auto;
  }

  .model-container {
    width: 100%;
    margin: 0 auto;
    padding: 40px 10px 50px;
  }

  .colors-wrapper {
    border: 0;
  }

  .car-img-wrapper {
    margin-top: 60px;
  }

  .features-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: max-content;
    margin: 0 auto;
    padding: 20px 25px 20px 15px;
    border-top: 1px solid #fff;

    &-close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
    }
  }
}

@media (max-width: 1280px) {

  .model-chooser,
  .content-container {
    display: none
  }

  .swiper-container {
    display: block;
  }

  .header-text {
    font-size: 22px;
  }

  .swiper {
    background-color: #000;
  }

  .swiper-slide {
    max-height: 0;
    position: relative;
    padding: 40px 20px;
    background: linear-gradient(180deg, #000 0%, transparent 60%), url("../images/configurator/conf-fon.jpg") no-repeat 100% 200px;
    background-size: cover;
    overflow: hidden;
    height: initial;
  }

  .swiper-slide.swiper-slide-active {
    max-height: 100%;
  }

  .guarantee {
    margin: 5px 0 20px;
  }

  .name-nav-wrapper {
    display: flex;
    align-items: center;
  }

  .name-wrapper {
    font-size: 40px;
  }

  .name {
    font-size: 30px;
  }

  .colors-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    margin-right: -20px;
    padding-right: 0;
  }

  .features {
    width: max-content;
    margin: 20px 0;
    list-style-type: none;
  }

  .feature {
    padding: 15px 0;
    border-bottom: 1px solid #fff;

    &:first-child {
      border-top: 1px solid #fff;
    }
  }

  .btn-specs {
    width: 100%;
    max-width: 290px;
  }

  .btn-blue {
    margin: 0;
  }

  .car-img {
    height: 200px;

    &:not(&.monjaro, &.okavango) {
      transform: scale(1.3);
    }
  }

  .car-img-wrapper {
    left: auto;
    margin: 80px auto;
    width: 100%;
    transform: none;
  }

  .navigation {
    display: flex;
    gap: 10px;
    margin-top: 18px;
  }

  .features-container {
    position: absolute;
    z-index: 10;
    bottom: 90px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: max-content;
    margin: 0 auto;
    padding: 20px;
    border: 0;
    background-color: rgba(#000, 0.8);
  }
}

@media (max-width: 600px) {
  .btn-blue {
    width: 100%;
    max-width: initial;

    &:hover {
      width: 100%;
    }
  }

  .name-wrapper {
    font-size: 25px;
  }

  .name {
    font-size: 18px;
  }

  .guarantee {
    font-size: 20px;
  }

  .features-container {
    grid-template-columns: 1fr;
    background-color: rgba(#000, 0.9);
  }

  .car-img {
    height: 320px !important;
  }
}

@media (max-width: 480px) {
  .car-img {
    position: relative;
    left: -25px;
    height: 200px !important;
  }

  .features {
    width: 200px;
  }

  .desc-item__textline {
    width: 100%;
    font-size: 12px;

    &:nth-child(2) {
      margin-top: 2px;
      font-size: 10px;
    }
  }
}

@media (max-width: 320px) {
  .car-img-wrapper {
    margin: 20px auto;
  }
}
</style>
