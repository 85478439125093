export default {
  CARS: [
    {
      id: "okavango",
      name: "OKAVANGO",
      // price: "3 029 990",
      // price_new: "1 349 990",
      // benefit: "450 000",
      benefit: "600 000",
      trade_in: "260 000",
      credit_ben: "260 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
      in_stock: false,
    },
    {
      id: "monjaro",
      name: "Monjaro",
      price: false,
      // price_new: "1 774 990",
      // benefit: "450 000",
      benefit: "800 000",
      credit_ben: "350 000",
      trade_in: "350 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
    },
    {
      id: "emgrand",
      name: "Emgrand",
      // price: "1 649 990",
      // price_new: "1 349 990",
      // benefit: "350 000",
      benefit: "400 000",
      trade_in: "260 000",
      credit_ben: "260 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
      in_stock: false,
    },
    {
			id: "cityray",
			name: "CITYRAY",
			price: "",
			price_new: "",
			//benefit: "390 000",
      benefit: "500 000",
			trade_in: "",
			credit_ben: "",
			kasko_ben: "",
			inst_plan: false,
			credit: true,
			payment: true,
			configurable: true,
			in_stock: false,
		},
    {
      id: "preface",
      name: "PREFACE",
      // price: "2 699 990",
      // price_new: "1 349 990",
      // benefit: "450 000",
      benefit: "500 000",
      trade_in: "260 000",
      credit_ben: "260 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
      in_stock: false,
    },
    {
      id: "atlas_new",
      name: "Atlas NEW",
      // price: "2 879 990",
      // price_new: "1 349 990",
      // benefit: "350 000",
      benefit: "500 000",
      trade_in: "260 000",
      credit_ben: "260 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
      in_stock: false,
    },
    {
      id: "coolray_new",
      name: "Coolray NEW",
      // price: "1 649 990",
      // price_new: "1 349 990",
      // benefit: "300 000",
      benefit: "500 000",
      trade_in: "260 000",
      credit_ben: "260 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
      in_stock: false,
    },
    // {
    //   id: "tugella-facelift",
    //   name: "Tugella",
    //   car_name: "TUGELLA",
    //   // price: "2 124 990",
    //   // price_new: "1 774 990",
    //   bonus: "100 000",
    //   benefit: "680 000",
    //   credit_ben: "350 000",
    //   trade_in: "350 000",
    //   kasko_ben: "",
    //   inst_plan: false,
    //   credit: true,
    //   payment: true,
    //   configurable: true,
    // },
    
    // {
    //   id: "coolray",
    //   name: "Coolray",
    //   // price: "1 649 990",
    //   // price_new: "1 349 990",
    //   benefit: "650 000",
    //   trade_in: "260 000",
    //   credit_ben: "260 000",
    //   kasko_ben: "",
    //   inst_plan: false,
    //   credit: true,
    //   payment: true,
    //   configurable: true,
    // },
    // {
    // id: "belgee-x50",
    // name: "Belgee X50",
    // // price: "1 649 990",
    // // price_new: "1 349 990",
    // benefit: "350 000",
    // trade_in: "260 000",
    // credit_ben: "260 000",
    // kasko_ben: "",
    // inst_plan: false,
    // credit: true,
    // payment: true,
    // configurable: true,
    // in_stock: false,
    // },

    /* {
    id: "tugella",
    name: "TUGELLA",
    price: "2 869 990",
    price_new: "2 469 990",
    benefit: "750 000",
    trade_in: "350 000",
    credit_ben: "350 000",
    kasko_ben: "",
    inst_plan: false,
    credit: true,
    payment: true,
    configurable: true,
  }, */
    {
      id: "atlas-pro",
      name: "Atlas PRO",
      // price: "2 124 990",
      // price_new: "1 774 990",
      // benefit: "600 000",
      benefit: "700 000",
      credit_ben: "350 000",
      trade_in: "350 000",
      kasko_ben: "",
      inst_plan: false,
      credit: true,
      payment: true,
      configurable: true,
    },
  ],
};
