<template>
  <div class="block-cars-container" :class="device_platform">
    <div class="header-text">Модельный ряд</div>

    <div class="cars-wrapper">
      <div
        class="car"
        :class="{ 'coming-soon': car.coming_soon }"
        :id="car.id"
        v-for="(car, index) in cars"
        :key="index"
      >
        
        <div class="car__img-block" :class="`car__img-block--${car.id}`">
          <div v-if="car.in_stock" class="car__stock-label">Уже в салоне</div>
          <img
            class="car__bg"
            :src="require(`../../images/background/${car.id}-bg.png`)"
            alt=""
          />
          <img
            class="car__img"
            :src="require(`../../images/cars/car-${car.id}.png`)"
            alt=""
          />
          <div
            class="car__title"
            :class="{
              'car__title--long': carsLongNames.includes(car.id),
            }"
          >
            <template v-if="['tugella-facelift'].includes(car.id)">
              Geely <span class="car__model" v-html="car.car_name"></span>
            </template>
            <template v-else>
              Geely <span class="car__model" v-html="car.name"></span>
            </template>
          </div>
          
        </div>

        <div class="car__content">
          <div class="benefits">
            <div class="benefits__item" v-if="car.price">
              <img class="benefits__icon" src="../../images/icons/price.png" alt="">
              <div class="price-wrapper">
                <p class="benefits__price">от <span class="benefits__price-color">{{ car.price }}</span>&nbsp;<span class="ruble">₽</span></p>
                <p v-if="car.price_new" class="benefits__price benefits__price_new">от <span class="benefits__price-new">{{ car.price_new }}</span>&nbsp;<span class="ruble ruble_small">₽</span></p>
              </div>
              
            </div>

            <div
              class="benefits__item"
              v-if="car.credit_ben && car.id == 'atlas' && false"
            >
              <img
                class="benefits__icon"
                src="../../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">Выгода при кредите</p>
            </div>

            <div
              class="benefits__item"
              v-if="car.kasko_ben && car.id == 'atlas' && false"
            >
              <img
                class="benefits__icon"
                src="../../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">Выгода при КАСКО страховании</p>
            </div>

            <div class="benefits__item" v-if="car.benefit">
              <img
                class="benefits__icon"
                src="../../images/icons/trade_in.png"
                alt=""
              />

              <p class="benefits__text">
                Выгода
                <nobr
                  >до
                  <span class="benefits__color">{{ car.benefit }}</span></nobr
                >
                ₽
              </p>
            </div>

            <div class="benefits__item" v-if="car.kasko_ben">
              <img
                class="benefits__icon"
                src="../../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">
                Скидка при КАСКО
                <nobr
                  >до
                  <span class="benefits__color">{{ car.kasko_ben }}</span></nobr
                >
                ₽
              </p>
            </div>

            <div class="benefits__item" v-if="car.inst_plan">
              <img
                class="benefits__icon"
                src="../../images/icons/inst_plan.png"
                alt=""
              />
              <p class="benefits__text">
                Рассрочка <span class="benefits__color">0%</span><sup>*</sup>
              </p>
            </div>
            <div class="benefits__item" v-if="car.credit">
              <img
                class="benefits__icon"
                src="../../images/icons/inst_plan.png"
                alt=""
              />
              <p class="benefits__text">
                Кредит от <span class="benefits__color">0,01%</span> <sup>***</sup>
              </p>
            </div>

            <div class="benefits__item">
              <img
                class="benefits__icon"
                src="../../images/icons/tires_blue.svg"
                alt=""
              />
              <p class="benefits__text">
                Доп. оборудование на <span class="benefits__color">100 000</span> ₽ в подарок
              </p>
            </div>
            <div class="benefits__item">
              <img
                class="benefits__icon"
                src="../../images/icons/calendar_blue.svg"
                alt=""
              />
              <p class="benefits__text">
                Рассрочка <span class="benefits__color">0%</span> на два года
              </p>
            </div>
          </div>
          <div class="buttons-container">
            <div
              class="btn-blue btn__arrow_1"
              @click="
                getCall({
                  type: 'sale',
                  form: 'cars',
                  callback_title: 'РАССЧИТАТЬ КРЕДИТ',
                })
              "
            >
              РАССЧИТАТЬ КРЕДИТ
            </div>
            <div
              class="btn-blue btn__arrow_2"
              @click="
                getCall({
                  type: 'sale',
                  form: 'cars',
                  callback_title: 'СТОИМОСТЬ ПО АКЦИИ',
                })
              "
            >
              СТОИМОСТЬ ПО АКЦИИ
            </div>
          </div>
        </div>

        <page-slider
          class="car__slider"
          :model="car.id"
          @openGallery="openGallery"
        />
      </div>
    </div>

    <full-gallery
      v-if="showGallery"
      :model="galleryModel"
      :index="galleryIndex"
      @close="closeGallery"
    />
  </div>
</template>

<script>
import Mixin from "../../common/mixin";
import CarsInfo from "../../common/cars-info";
import finance from "../../common/finance";
import { eventBus } from "../../main";
import $ from "jquery";
import Swiper from "swiper";
import PageSlider from "@/components/block-cars/components/page-slider.vue";
import FullGallery from "@/components/block-cars/components/full-gallery";

export default {
  name: "block-cars",
  mixins: [Mixin, finance],
  components: {FullGallery, PageSlider},
  data() {
    return {
      showGallery: false,
      galleryModel: '',
      galleryIndex: 0,
      cars: CarsInfo.CARS,
      carsLongNames: ['coolray_new', 'atlas_new']
    };
  },
  methods: {
    scrollTo(where, id) {
      let newhash = "#" + where;
      history.replaceState(null, null, newhash);
      var delta = 0;
      if (this.device_platform === "desktop") {
        if ($(window).width() < 1607) delta = 30;
        else delta = -30;
      } else delta = -30;
      eventBus.$emit("setActiveCar", id);
      this.$emit("scrollTo", where, delta);
    },
    getCall(data) {
      this.$emit("getCall", data);
    },
    openGallery(model, index){
      this.showGallery = true
      this.galleryModel = model
      this.galleryIndex = index
    },
    closeGallery(){
      this.showGallery = false
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/constants.scss";

.block-cars-container {
  width: 100%;
  padding: 80px 0 140px;
  position: relative;

  @media (max-width: 1200px) {
    padding: 60px 0 20px;
  }

  @media (max-width: 800px) {
    padding-top: 20px;
  }
}

.header-text {
  text-transform: uppercase;
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-bottom: 40px;
  }
}

.cars-wrapper {
  margin: 0px auto;
  width: 100%;
  max-width: 1640px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.car {
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 130px;
  z-index: 1;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
  }

  &__slider {
    position: absolute;
    left: 20px;
    width: 150px;
    max-height: 350px;

    @media (max-width: 1200px) {
      position: relative;
      left: auto;
      width: 100%;
    }
  }

  &:not(:last-child) {
    margin-bottom: 180px;

    @media (max-width: 1200px) {
      margin-bottom: 80px;
    }
    @media (max-width: 600px) {
      margin-bottom: 50px;
    }
  }

  &__swiper-slide {
    width: 120px;
    height: 90px;

    img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 80px;
    width: 100%;
    max-width: 500px;

    @media (max-width: 1500px) {
      min-width: 350px;
      max-width: 450px;
      margin: 0 40px 0px 20px;
    }
    @media (max-width: 1200px) {
      margin: 0 auto;
    }
    @media (max-width: 600px) {
      min-width: auto;
      width: 100%;
      padding: 0 20px;
    }
  }

  &__img-block {
    position: relative;
    display: flex;
    user-select: none;

    &--atlas-pro {
      .car__bg {
        // transform: scaleX(-1);
      }
    }

    @media (max-width: 1500px) {
      align-self: flex-start;
    }
    @media (max-width: 1200px) {
      margin-bottom: 150px;
      width: 100%;
    }
    @media (max-width: 600px) {
      margin-bottom: 100px;
    }
  }

  &__stock-label {
    position: absolute;
    left: 30%;
    top: -7%;

    background: url('../../images/background/label-red.png') center no-repeat;
    background-size: 100%;
    width: 255px;
    height: 60px;

    font-size: 27px; 
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      width: 180px;
      height: 36px;
      font-size: 18px;
    }

    @media (max-width: 400px) {
      top: -15%;
    }
  }

  &__bg {
    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  &__img {
    position: absolute;
    width: 700px;
    bottom: -140px;
    left: 150px;
    transition: all 0.5s;
    cursor: pointer;

    @media (max-width: 1500px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      bottom: -80px;
      left: 30px;
    }

    &:hover {
      transform: translateX(30px);
    }
  }

  &__title {
    position: absolute;
    top: 50px;
    left: 155px;
    color: #fff;
    font-size: 48px;
    font-weight: 400;

    &--long {
      font-size: 44px;
      // top: 30px;
      left: 110px;
    }

    @media (max-width: 1400px) {
      //left: 190px;

      &--long {
        // top: 30px;
      }
    }
    @media (max-width: 600px) {
      left: 3%;
      top: 8px;
      font-size: 25px;

      &--long {
        max-width: 200px;
        left: 10%;
      }

    }
  }

  &__model {
    text-transform: uppercase;
    font-size: 62px;
    font-weight: 700;

    @media (max-width: 1400px) {
      font-size: 52px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
    }
  }

  &__on-stock {
    position: absolute;
    top: -35px;
    right: -35px;
    background-color: $dark-blue;
    color: #FFFFFF;
    padding: 16px 25px;
    font-size: 27px;
    transform: skewX(-15deg);

    p {
      transform: skewX(15deg);
    }

    @media (max-width: 1400px) {
      padding: 12px 20px;
      font-size: 26px;
      top: -25px;
      right: -25px;
    }

    @media (max-width: 1200px) {
      font-size: 26px;
      top: -25px;
      right: 25px;
    }

    @media (max-width: 600px) {
      padding: 10px 16px;
      font-size: 3vw;
      top: -20px;
      right: 15px;
    }
  }

  &:nth-child(even) {
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 100px;

    @media (max-width: 1200px) {
      padding: 0 20px;
    }

    .car__img-block {
      order: 2;

      @media (max-width: 1200px) {
        order: 0;
      }
    }

    .car__img {
      left: auto;
      right: 128px;

      &:hover {
        transform: translateX(-30px);
      }

      @media (max-width: 1500px) {
        left: 30px;
      }
    }
    .car__title {
      &:not(&--long) {
        left: auto;
        right: 170px;
        top: 60px;
      }

      &--long {
        margin-left: 80px;
      }

      @media (max-width: 1500px) {
        text-align: center;
        top: 20px;
        right: 60px;
        &:not(&--long) {
          text-align: center;
          top: 20px;
        }

        &--long {
          top: 20px;
          right: 50px;
        }
      }

      @media (max-width: 1500px) {
        &--long {
          left: auto;
        }
      }

      @media (max-width: 900px) {
        &--long {
          left: auto;
          top: 15%;
        }

        &:not(&--long) {
          top: 20%;
          right: auto;
          left: 29%;
        }
      }
      
      @media (max-width: 600px) {
        &--long {
          top: 7%;
        }

        &:not(&--long) {
          top: 20%;
          right: auto;
          left: 29%;
        }
      }
      @media (max-width: 330px) {
        &--long {
          margin-left: 66px;
        }
      }
    }

    .car__content {
      @media (max-width: 1500px) {
        margin: 0 20px 0px 40px;
      }
      @media (max-width: 1200px) {
        margin: 0 auto;
      }
      @media (max-width: 600px) {
        min-width: auto;
        width: 100%;
        width: 100%;
        padding: 0 20px;
      }
    }

    .car__slider {
      left: auto;
      right: 20px;

      @media (max-width: 1200px) {
        right: auto;
      }
    }
  }
}

#tugella-facelift {
  .car__img {
    width: 600px;

    @media (max-width: 1500px) {
      width: 80%;
    }
  }
}

@media (max-width: 1400px) {

  #tugella-facelift {
    .car__img {
      bottom: -200px;
    }
  }

  #atlas-pro {
    .car__title {
      right: 50px;
    }
  }
}


@media (max-width: 600px) {

  #tugella-facelift {

    .car__img {
      bottom: -100px;
    }
  }

  #coolray_new {
    .car__model {
      white-space: nowrap;
    }
  }

  #atlas-pro {

    .car__img {
      bottom: -55px;
    }
  }
  #monjaro {

    .car__img {
      bottom: -55px;
    }
  }
}

.benefits {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;

      @media (max-width: 600px) {
        margin-bottom: 10px;
      }
    }
  }

  &__icon {
    width: 35px;
    margin-right: 25px;

    @media (max-width: 600px) {
      width: 30px;
      margin-right: 20px;
    }
  }

  &__text {
    font-size: 19px;

    &_large {
      font-size: 29px;
    }

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  &__color {
    font-size: 24px;
    color: #3999FF;

    &_large_bold {
      font-size: 29px;
      font-weight: 700;
    }

    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  &__price {
    font-size: 32px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 20px;
    }

    &_new {
      font-size: 22px;
      padding: 4px 8px 6px 8px;
      background: #cbd6dc;
      position: relative;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }

    &:not(:last-child) {
      margin-right: 30px;

      @media (max-width: 400px) {
        margin-bottom: 8px;
        margin-right: 0;
      }
    }
  }
  &__price-color {
    color: #356fb1;
    //text-decoration: line-through;
  }
  &__price-new {
    color: #356fb1;
  }

  .price-wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.ruble {
  font-family: "ruble";
  font-size: 32px;

  @media (max-width: 600px) {
    font-size: 20px;
  }

  &_small {
    font-size: 20px;
    font-weight: 400;
    font-size: 24px;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
}

.buttons-container {
  width: 100%;
}

.btn-blue {
  border: 1px solid $blue;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  height: 50px;
  transition: all 0.5s;

  @media (max-width: 1500px) {
    max-width: 400px;
  }
  @media (max-width: 600px) {
    height: 45px;
    max-width: 100%;
  }
  @media (max-width: 400px) {
    height: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
}

.btn__arrow {
  &_1 {
    position: relative;
    background: transparent;
    width: 100%;
    border: 0;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url(../../images/icons/btn_bg.png) no-repeat center;
      background-size: 100%;
      z-index: -1;
    }

    &:hover {
      background: $dark-blue;
      color: #fff;
    }
  }

  &_2 {
    position: relative;
    background: $dark-blue;
    width: 100%;
    border: 0;

    &:after {
      content: "";
      position: absolute;
      width: 94px;
      height: 100%;
      top: 0;
      right: 0;
      background: url(../../images/icons/arrow-btn.png) no-repeat center;
      background-size: 100%;

      @media (max-width: 400px) {
        right: -45px;
      }
    }

    &:hover {
      background: #cbd6dc;
      color: #000;
    }
  }
}

.btn-blue:hover {
  border: 0;
  width: 100%;
}

.btn-white {
  width: 100%;
}

.btn-white:hover {
  width: 100%;
}

sup {
  font-size: 16px;
}
</style>